import React, { Component } from 'react'
import styles from "./Footer.module.scss"
import { footerData } from '../../Config/config'
import { NavHashLink } from 'react-router-hash-link';

export class Footer extends Component {
  // constructor(props){
  //   super(props);
  //   this.state(
      
  //   )
  // }
  getYear() {
    return new Date().getFullYear();
  }
  render() {
    return (
      <div>
        <footer>
          <div className='container'>
            <div className={styles.footer_logo}>
                <div className={styles.logo}>
                <a href="/">
                 <img src='assets/logo2.png' alt='logo'/>  
                </a>
                </div>
                <h3>Keep me upto date with content, updates and offers from Us</h3>
            </div>
            <div className={styles.footer_wrap}>
              {footerData.map((ele)=>{
                return(
                  <div className={styles.info}>
                    <h4>{ele.type}</h4>
                    <ul>
                      {ele.subData.map(i=><div className={styles.cont_info}>{i.icon && (<i className={i.icon}></i>)}<li><NavHashLink to={i.url}>{i.li}</NavHashLink></li> </div>)}
                    </ul>
                  </div>
                )
              })}
              <div className={styles.info}>
                <h4>Contact us</h4>
                <ul>
                <div className={styles.cont_info}>
                  <i className="fa fa-phone"></i>
                  <li> +91-63645 01737</li>
                </div>
                <div className={styles.cont_info}>
                  <i className="fa-solid fa-envelope"></i>
                  <li> info@qreams.com</li>
                </div>
                <div className={styles.cont_info}>
                  <i className="fa-solid fa-location-dot"></i>
                  <li>Qream Solutions Pvt Ltd <br/>No 1, #302, 3rd floor, Navarathna Gardens <br/>Kanakapura Rd, Doddakallasandra<br/>Bangalore-560062, Karnataka, India.</li>
                </div>
                </ul>
              </div>

            </div>
            <div className={styles.footer_bottm}>
              <div>
              <p>© {this.getYear()} All Rights Reserved by Qream Solutions Pvt Ltd</p>
              <p><span>Pictures taken from templates of WPS Pro licensed version. Icons made by Pixel perfect and Freepik from www.flaticon.com </span></p>
              <p></p>
              </div>
                {/* <div>
                  <ul>
                    <li>
                      <a ><i class="fa-brands fa-square-facebook"></i></a>
                    </li>
                    <li>
                      <a ><i class="fa-brands fa-linkedin"></i></a>
                    </li>
                    <li>
                      <a ><i class="fa-brands fa-square-twitter"></i></a>
                    </li>
                  </ul>
                </div>
                 */}
            </div>
          </div>
        </footer>
      </div>
    )
  }
}

export default Footer
