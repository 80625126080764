import logo from './logo.svg';
import './App.scss';
// import { BrowserRouter, Routes, Route } from "react-router-dom";

import { BrowserRouter, Routes, Route } from "react-router-dom";


import Navbar from './conponents/Navbar/Navbar';
import Footer from './conponents/Footer/Footer';
import Home from './conponents/Home/Home';
import About from './conponents/About/About';
import Contact from './conponents/contact/Contact';
// import Health from './conponents/Home/health/Health';
// import Iot from './conponents/Home/iot/Iot';
// import Ecom from './conponents/Home/ecom/Ecom';
// import Cons from './conponents/Home/consultency/Cons';
import { useEffect, useState } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import Usecase from './conponents/usecase/Usecase';
import { useLocation } from 'react-router-dom';

function App() {
  useEffect(() => {
    AOS.init({
      once: true,
    });
  }, []);
  return (
    <div className="App">
      <BrowserRouter>
      <ScrollToTop />

      <Navbar/>
        <Routes>
            <Route index element={<Home/>}/>
            <Route path='/home' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/usecase' element={<Usecase />} />

            <Route path='/contact' element={<Contact />} />
        </Routes>
      <Footer/>
        <ScrollButton />
    </BrowserRouter>
    </div>
  );
}

export default App;

export const ScrollToTop = () => {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}

export const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTopp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <button className='topTOBack'
      onClick={scrollToTopp}
      //
    >
      <i
        style={{ display: visible ? "inline" : "none", position: "fixed" ,right:"65px" , bottom:"35px"}}
        class="fa-solid fa-arrow-up"
      ></i>
    </button>
  );
};
